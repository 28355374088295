.float-child-left-work {
    width: 20%;
    float: left;
    /* padding: 20px; */
    border-left: 2px solid #343a40;
    border-right: 2px solid #343a40;
    border-bottom: 2px solid #343a40;
    height:90vh;
    overflow: hidden;
}

.float-child-right-work {
    width: 80%;
    float: right;
    padding: 20px;
    border-right: 2px solid #343a40;
    border-bottom: 2px solid #343a40;
}

.company-logos {
    max-width: 50%;
    height: auto;
    width: auto\9;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
}

/*
 style={{boxSizing:"border-box", borderBottomLeftRadius:"1000px 1000px", borderBottomRightRadius:"1000px 1000px", borderTopLeftRadius:"1000px 1000px", borderTopRightRadius:"1000px 1000px"}}
style={{boxSizing:"border-box", width:"100%", height:"100%"}}
*/
