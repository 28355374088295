h4 {
    text-align: center;
}

h5 {
    text-align: center;
}

h6 {
    text-align: center;
}